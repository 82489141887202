@import "./layouts/stylesheet.css";

body {
  margin: 0;
  font-family: "Euclid Circular B", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  background-color: #010211;
  /* background-image: radial-gradient(circle at top right, rgba(121, 68, 154, 0.13),       transparent),
    radial-gradient(circle at 20% 80%, rgba(41, 196, 255, 0.13), transparent);

    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
    -webkit-backface-visibility: hidden;
    transform: perspective(-1px);
    backface-visibility: hidden; */
}

canvas {
  position: fixed;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
}

.language-changer {
  z-index: 1050;
  position: fixed !important;
  top: 16px;
  right: 16px;
  background-color: #1976d2;
  color: white !important;
  border-radius: 30px !important;
}

.animated-button {
  /* Other styles */
  transform: scale(1);
  transition: transform 0.5s, color 0.1s;
}

.animated-button:active {
  transform: scale(0.92);
}

.animated-button .text {
  /* Initial text styles */
  transition: transform 0.3s, color 1s; /* Add transitions */
}
.animated-button:active .text {
  /* Text styles for the active state */
  transform: rotate(15deg) scale(1.2); /* Rotate and scale up the text */
}

.animated-button .talent-number {
  /* Initial text styles */
  transition: transform 0.3s, color 1s; /* Add transitions */
}
.animated-button:active .talent-number {
  transform: rotate(15deg) scale(1.5); /* Rotate and scale up the text */
}

.talent-number {
  padding-top: 10px;
  font-size: 25px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* .talent-box-inner {
  height: 100px !important;
} */

.small-text {
  /* font-size: '10px'; */
  align-items: 'center'
}

.smaller-text {
  font-size: 10px;
}

.small-h1 {
  font-size: 1.2rem !important;
}

.margin-bottom-5 {
  margin-bottom: 40px;
}

.general-box {
  background-color: #fff;
  width: 80vw;
  overflow-x: hidden;
}

.zero-focus:focus {outline:0;}

.overflow-large {
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .small-text {
    font-size: 10px !important;
    align-items: center;
  }

  .small-h2 {
    font-size: 16px !important;
  }

  .margin-bottom-5 {
    margin-bottom: 10px;
  }
  
}

@media only screen and (min-width : 1024px) {
  .talent-box-inner {
    height: 60px !important;
  }

  .talent-type-h2 {
    font-size: 10px;
  }

  .talent-number {
    font-size: 12px;
  }

  .talent-title {
    font-size: 7px;
  }

  .top-ten-title {
    font-size: 24px;
  }
}


@media only screen and (min-width : 1200px) {

  .talent-type-h2 {
    font-size: 14px;
  }

  .talent-number {
    font-size: 14px;
  }

  .talent-title {
    font-size: 8px;
  }
}

@media only screen and (min-width : 1440px) {
  .talent-box-inner {
    height: 100px !important;
  }

  .talent-type-h2 {
    font-size: 16px;
  }

  .talent-number {
    font-size: 18px;
  }

  .talent-title {
    font-size: 10px;
  }
  
  .top-ten-title {
    font-size: 32px;
  }
}

@media only screen and (min-width : 1536px) {
  .talent-box-inner {
    height: 100px !important;
  }

  .talent-type-h2 {
    font-size: 16px;
  }

  .talent-number {
    font-size: 20px;
  }

  .talent-title {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .small-h1 {
    font-size: 1rem !important;
  }

  .general-box {
    background-color: #fff;
    width: 90vw;
    overflow-x: hidden;
  }
  
  .language-changer {
    bottom: 20px;
    left: 16px;
    top: inherit;
    right: inherit;
  }

  .overflow-large {
    overflow: hidden;
  }

  .talent-box-inner {
    height: 100px !important;
  }
}