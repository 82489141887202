/* Euclid Circular B - font */
@font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}
/* @font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-SemiBold-Italic.ttf");
  font-weight: 600;
  font-style: normal;
} */
@font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
/* @font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Medium-Italic.ttf");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
/* @font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Light-Italic.ttf");
  font-weight: normal;
  font-style: normal;
} */
/* @font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Italic.ttf");
  font-weight: normal;
  font-style: normal;
} */
@font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
/* @font-face {
  font-family: "Euclid Circular B";
  src: url("../assets/fonts/Euclid-Circular-B-Bold-Italic.ttf");
  font-weight: normal;
  font-style: normal;
} */
